import React, { ReactNode } from 'react';
import { Larrow } from '../../assets/Homeicons/L-arrow.svg';
import { Rarrow } from '../../assets/Homeicons/R-arrow.svg';
import { Container, ArrowButton, HeaderArrows, HeaderInfo, HeaderTitle, HorizontalScroll, SeeAll, Grid } from './styles';
import { t } from 'i18next';

interface ItemsScrollProps {
  header?: React.FC<{elements: React.MutableRefObject<any>}>;
  gap?: number;
}

const HorizontalItemsScroll: React.FC<ItemsScrollProps> = props => {
  const elements = React.useRef(null);

  return (
    <Container>
      {props.header({elements})}
      <HorizontalScroll ref={elements} gap={props.gap ?? 10}>
        {props.children}
      </HorizontalScroll>
    </Container>
  );
};

interface GridProps {
  gridMinPx: number;
  padding?: string;
}

const GridItems: React.FC<ItemsScrollProps & GridProps> = props => {
  const elements = React.useRef(null);

  return (
    <Container>
      {props.header({elements})}
      <Grid ref={elements} gap={props.gap ?? 10} gridMinPx={props.gridMinPx} padding={props.padding}>
        {props.children}
      </Grid>
    </Container>
  );
};

interface ItemsScrollDefaultHeaderProps {
  elements: React.MutableRefObject<any>;
  headerText: string;
  headerSeeAllColor?: string;
  headerColor?: string;
  onSeeAllClick?: () => void;
}

const ItemsScrollDefaultHeader: React.FC<ItemsScrollDefaultHeaderProps> = props => {
  const sideScroll = (
    element: any,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  
  return (
    <HeaderInfo>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <HeaderTitle color={props.headerColor}>{props.headerText}</HeaderTitle>
        <HeaderArrows>
          <ArrowButton
            onClick={() => {
              sideScroll(props.elements.current, 10, 200, -10);
            }}
          >
            <Larrow fill={props.headerColor} />
          </ArrowButton>
          <ArrowButton
            onClick={() => {
              sideScroll(props.elements.current, 10, 200, 10);
            }}
          >
            <Rarrow fill={props.headerColor} />
          </ArrowButton>
        </HeaderArrows>
      </div>
      {props.onSeeAllClick ?
        <SeeAll color={props.headerSeeAllColor} onClick={props.onSeeAllClick}>{t('see-all')}</SeeAll>
        : null}
    </HeaderInfo>
  )
}

export {HorizontalItemsScroll, GridItems, ItemsScrollDefaultHeader};
